<template>
  <a-drawer
    title="创建/编辑分销商"
    width="700"
    :visible="visible"
    :closable="false"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <!-- from -->
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 名称 -->
      <a-form-item
        label="名称"
        name="name"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          v-model:value="formState.name"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 公司主体 -->
      <a-form-item
        name="company_name"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <template #label>
          <span style="margin-right: 2px;">公司主体</span>
          <a-tooltip>
            <template #title>需跟合同签约的公司主体名称一致</template>
            <info-circle-outlined />
          </a-tooltip>
        </template>
        <a-input
          v-model:value="formState.company_name"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 收款账号 -->
      <a-form-item
        label="收款账号"
        name="bank_account"
        :rules="[{ required: true, validator: validatorBankAccount }]"
      >
        <a-input
          v-model:value="formState.bank_account"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 开户行 -->
      <a-form-item
        label="开户行"
        name="bank_branch"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          v-model:value="formState.bank_branch"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- IAP微小分成比例 -->
      <a-form-item
        name="wechat_cash_rate"
        :rules="[{ required: true, validator: validatorCashRate }]"
      >
        <template #label>
          <span style="margin-right: 2px;">IAP微小分成比例</span>
          <a-tooltip>
            <template #title>按充值金额进行比例分成。修改分成比例只对修改后的数据生效。</template>
            <info-circle-outlined />
          </a-tooltip>
        </template>
        <a-input
          class="form-input"
          v-model:value="formState.wechat_cash_rate"
          placeholder="请输入"
          suffix="%"
        />
      </a-form-item>
      <!-- IAP其他分成比例 -->
      <a-form-item
        name="other_cash_rate"
        :rules="[{ required: true, validator: validatorCashRate }]"
      >
        <template #label>
          <span style="margin-right: 2px;">IAP其他分成比例</span>
          <a-tooltip>
            <template #title>按充值金额进行比例分成。修改分成比例只对修改后的数据生效。</template>
            <info-circle-outlined />
          </a-tooltip>
        </template>
        <a-input
          class="form-input"
          v-model:value="formState.other_cash_rate"
          placeholder="请输入"
          suffix="%"
        />
      </a-form-item>
      <!-- IAA广告分成比例 -->
      <a-form-item
        name="iaa_ad_cash_rate"
        :rules="[{ required: true, validator: validatorCashRate }]"
      >
        <template #label>
          <span style="margin-right: 2px;">IAA广告分成比例</span>
          <a-tooltip>
            <template #title>按广告收入进行比例分成。修改分成比例只对修改后的数据生效。</template>
            <info-circle-outlined />
          </a-tooltip>
        </template>
        <a-input
          class="form-input"
          v-model:value="formState.iaa_ad_cash_rate"
          placeholder="请输入"
          suffix="%"
        />
      </a-form-item>
      <!-- IAA微小分成比例 -->
      <a-form-item
        name="iaa_wechat_cash_rate"
        :rules="[{ required: true, validator: validatorCashRate }]"
      >
        <template #label>
          <span style="margin-right: 2px;">IAA微小分成比例</span>
          <a-tooltip>
            <template #title>按充值金额进行比例分成。修改分成比例只对修改后的数据生效。</template>
            <info-circle-outlined />
          </a-tooltip>
        </template>
        <a-input
          class="form-input"
          v-model:value="formState.iaa_wechat_cash_rate"
          placeholder="请输入"
          suffix="%"
        />
      </a-form-item>
      <!-- IAA其他分成比例 -->
      <a-form-item
        name="iaa_other_cash_rate"
        :rules="[{ required: true, validator: validatorCashRate }]"
      >
        <template #label>
          <span style="margin-right: 2px;">IAA其他分成比例</span>
          <a-tooltip>
            <template #title>按充值金额进行比例分成。修改分成比例只对修改后的数据生效。</template>
            <info-circle-outlined />
          </a-tooltip>
        </template>
        <a-input
          class="form-input"
          v-model:value="formState.iaa_other_cash_rate"
          placeholder="请输入"
          suffix="%"
        />
      </a-form-item>
      <!-- 结算周期 -->
      <a-form-item
        label="结算周期"
        name="cash_cycle"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-select
          class="form-input"
          v-model:value="formState.cash_cycle"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in SettlementCycle"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 商务 -->
      <a-form-item
        label="商务"
        name="business_id"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-select
          class="form-input"
          v-model:value="formState.business_id"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in businessList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.nickname }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 业务归属 -->
      <a-form-item
        label="业务归属"
        name="business_owner"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-select
          class="form-input"
          v-model:value="formState.business_owner"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in BusinessOwners"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 合同截止日期 -->
      <a-form-item
        label="合同截止日期"
        name="contract_deadline"
        :rules="[{ required: true }]"
      >
        <a-date-picker
          class="form-input"
          v-model:value="formState.contract_deadline"
          :format="dateFormat"
          :disabledDate="disabledDate"
        />
      </a-form-item>
      <!-- 用户名 -->
      <a-form-item
        label="用户名"
        name="username"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.username"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 密码 -->
      <a-form-item
        label="密码"
        name="password"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.password"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 微信小程序 -->
      <a-form-item
        label="微信小程序"
        name="wxplatforms"
      >
        <a-select
          v-model:value="formState.wxplatforms"
          mode="multiple"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filterOption="filterOption"
        >
          <a-select-option
            v-for="item in wxPlatformList"
            :key="`${item.project_platform_id}-${item.name}`"
            :value="item.project_platform_id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 抖音小程序 -->
      <a-form-item
        label="抖音小程序"
        name="ttplatforms"
      >
        <a-select
          v-model:value="formState.ttplatforms"
          mode="multiple"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filterOption="filterOption"
        >
          <a-select-option
            v-for="item in ttPlatformList"
            :key="`${item.project_platform_id}-${item.name}`"
            :value="item.project_platform_id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 快手小程序 -->
      <a-form-item
        label="快手小程序"
        name="ksplatforms"
      >
        <a-select
          v-model:value="formState.ksplatforms"
          mode="multiple"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filterOption="filterOption"
        >
          <a-select-option
            v-for="item in ksPlatformList"
            :key="`${item.project_platform_id}-${item.name}`"
            :value="item.project_platform_id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 快应用 -->
      <a-form-item
        label="快应用"
        name="qappplatforms"
      >
        <a-select
          v-model:value="formState.qappplatforms"
          mode="multiple"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filterOption="filterOption"
        >
          <template v-for="item in qappPlatformList">
            <a-select-option
              v-if="$pub.ljProjectIDs().includes(item.project_id)"
              :key="`${item.project_platform_id}-${item.name}`"
              :value="item.project_platform_id"
            >
              {{ item.name }}
            </a-select-option>
          </template>
        </a-select>
      </a-form-item>
      <!-- H5 -->
      <a-form-item
        label="H5"
        name="h5platforms"
      >
        <a-select
          v-model:value="formState.h5platforms"
          mode="multiple"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filterOption="filterOption"
        >
          <template v-for="item in h5PlatformList">
            <a-select-option
              v-if="$pub.ljProjectIDs().includes(item.project_id)"
              :key="`${item.project_platform_id}-${item.name}`"
              :value="item.project_platform_id"
            >
              {{ item.name }}
            </a-select-option>
          </template>
        </a-select>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { nextTick } from 'process'
import { SettlementCycle, BusinessOwners } from '@/utils/constantList'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'
import { resellerSave, resellerDetail, resellerBusiness, resellerPlatformList, distributorDetail } from '@/api/copyright'
import { message } from 'ant-design-vue'
import md5 from 'js-md5'
import dayjs from 'dayjs'
import { PasswordRule } from '@/utils/constantList'

// emit
const emit = defineEmits(['success'])
// from
const RefForm = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 修改对象
let detail = ref(null)
// 商务列表
let businessList = ref([])
// 平台列表
let wxPlatformList = ref([])
let ttPlatformList = ref([])
let ksPlatformList = ref([])
let qappPlatformList = ref([])
let h5PlatformList = ref([])
// 日期格式
const dateFormat = 'YYYY-MM-DD'
// 表单参数
const formState = reactive({
  // 名称
  name: undefined,
  // 公司主体
  company_name: undefined,
  // 收款账号
  bank_account: undefined,
  // 开户行
  bank_branch: undefined,
  // IAP微小分成比例
  wechat_cash_rate: undefined,
  // IAP其他分成比例
  other_cash_rate: undefined,
  // IAA广告分成比例
  iaa_ad_cash_rate: undefined,
  // IAA其他分成比例
  iaa_other_cash_rate: undefined,
  // IAA微小分成比例
  iaa_wechat_cash_rate: undefined,
  // 周期
  cash_cycle: undefined,
  // 商务
  business_id: undefined,
  // 业务归属
  business_owner: undefined,
  // 合同截止日期
  contract_deadline: undefined,
  // 用户名
  username: undefined,
  // 密码
  password: undefined,
  // 平台
  platform: [],
  wxplatforms: [],
  ttplatforms: [],
  ksplatforms: [],
  qappplatforms: [],
  h5platforms: []
})

// 显示
function showDrawer (data) {
  // 记录
  detail.value = data
  // 显示
  visible.value = true
  // 延迟处理
  nextTick(() => {
    // 获取选择项列表
    getSelectList()
    // 获取项目
    if (!!data) { getDetail() }
  })
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    resetFields()
    visible.value = false
  }
}

// 重置
function resetFields () {
  RefForm.value.resetFields()
}

// 获取选择项列表
function getSelectList () {
  resellerBusiness({ type: 0 }).then(res => {
    const { code, msg, data } = res
    if (code === 0) {
      businessList.value = data
    }
  })
  resellerPlatformList({ platform_id: 1 }).then(res => {
    const { code, msg, data } = res
    if (code === 0) {
      ttPlatformList.value = data
    }
  })
  resellerPlatformList({ platform_id: 2 }).then(res => {
    const { code, msg, data } = res
    if (code === 0) {
      ksPlatformList.value = data
    }
  })
  resellerPlatformList({ platform_id: 3 }).then(res => {
    const { code, msg, data } = res
    if (code === 0) {
      wxPlatformList.value = data
    }
  })
  resellerPlatformList({ platform_id: 4 }).then(res => {
    const { code, msg, data } = res
    if (code === 0) {
      qappPlatformList.value = data
    }
  })
  resellerPlatformList({ platform_id: 5 }).then(res => {
    const { code, msg, data } = res
    if (code === 0) {
      h5PlatformList.value = data
    }
  })
}

// 片库详情
function getDetail () {
  isLoading.value = true
  resellerDetail({ id: detail.value.id }).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0) {
      // 赋值
      formState.name = data.name
      formState.company_name = data.company_name
      formState.bank_account = data.bank_account
      formState.bank_branch = data.bank_branch
      formState.wechat_cash_rate = data.wechat_cash_rate
      formState.other_cash_rate = data.other_cash_rate
      formState.iaa_ad_cash_rate = data.iaa_ad_cash_rate
      formState.iaa_other_cash_rate = data.iaa_other_cash_rate
      formState.iaa_wechat_cash_rate = data.iaa_wechat_cash_rate
      formState.cash_cycle = data.cash_cycle
      formState.business_id = data.business_id
      formState.business_owner = data.business_owner
      formState.username = data.username
      formState.password = data.password
      formState.contract_deadline = data.contract_deadline && dayjs(data.contract_deadline) || undefined

      // 处理平台
      const platform = data.platform || []
      const wxplatforms = []
      const ttplatforms = []
      const ksplatforms = []
      const qappplatforms = []
      const h5platforms = []
      platform.forEach(item => {
        if (item.platform_id === 1) {
          ttplatforms.push(item.project_platform_id)
        } else if (item.platform_id === 2) {
          ksplatforms.push(item.project_platform_id)
        } else if (item.platform_id === 3) {
          wxplatforms.push(item.project_platform_id)
        } else if (item.platform_id === 4) {
          qappplatforms.push(item.project_platform_id)
        } else if (item.platform_id === 5) {
          h5platforms.push(item.project_platform_id)
        }
      })
      formState.wxplatforms = wxplatforms
      formState.ttplatforms = ttplatforms
      formState.ksplatforms = ksplatforms
      formState.qappplatforms = qappplatforms
      formState.h5platforms = h5platforms
      console.log(formState)
    } else {
      message.error(res.message)
    }
  }).catch((err) => {
    console.log(err)
    isLoading.value = false
    message.error('获取详情失败')
  })
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then((res => {
    // 处理平台
    const platform = []
    res.wxplatforms.forEach(id => {
      const p = wxPlatformList.value.find(item => item.project_platform_id == id)
      if (p) { platform.push(p) }
    })
    res.ttplatforms.forEach(id => {
      const p = ttPlatformList.value.find(item => item.project_platform_id == id)
      if (p) { platform.push(p) }
    })
    res.ksplatforms.forEach(id => {
      const p = ksPlatformList.value.find(item => item.project_platform_id == id)
      if (p) { platform.push(p) }
    })
    res.qappplatforms.forEach(id => {
      const p = qappPlatformList.value.find(item => item.project_platform_id == id)
      if (p) { platform.push(p) }
    })
    res.h5platforms.forEach(id => {
      const p = h5PlatformList.value.find(item => item.project_platform_id == id)
      if (p) { platform.push(p) }
    })
    // 上传数据
    const params = {
      id: detail.value && detail.value.id,
      ...res,
      contract_deadline: formState.contract_deadline.format(dateFormat),
      platform,
      password: formState.password === PasswordRule ? PasswordRule : md5(res.password)
    }
    isLoading.value = true
    resellerSave(params).then((res) => {
      isLoading.value = false
      if (res.code === 0) {
        if (params.id) {
          message.success('编辑成功')
        } else {
          message.success('新增成功')
        }
        onClose()
        emit('success')
      } else {
        message.error(res.message)
      }
    }).catch(() => {
      isLoading.value = false
      message.error('提交失败')
    })
  })).catch((err) => {
    console.log(err)
  })
}

// 不可选中当天之前的日期（今日可选中）
function disabledDate (current) {
  return current && current < dayjs().subtract(1, 'days').endOf('day')
}

// 效验 - 收款账号
function validatorBankAccount (rule, value) {
  if (!value) {
    return Promise.reject('请输入')
  } else if (!Pub.REG_IS_INTEGER(value)) {
    return Promise.reject('必须为纯数字')
  }
  return Promise.resolve()
}
// 效验 - 分成比例
function validatorCashRate (rule, value) {
  if (!value) {
    return Promise.reject('请输入')
  } else if (!Pub.REG_IS_NUMBER(value)) {
    return Promise.reject('必须为正数')
  } else if (Pub.CHECK_NUMBER_DECIMAL(value, 2)) {
    return Promise.reject('最多 2 位小数')
  }
  return Promise.resolve()
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.form-input {
  width: 50%;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 118px;
  height: 148px;
}
</style>