<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="`片库设置【${detail.name}】`"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="content">
      <!-- 功能条 -->
      <div class="tool-view">
        <!-- 弹簧 -->
        <div style="flex: 1;"></div>
        <!-- 操作 -->
        <a-button class="tool-item" @click="touchVisible(true)">全部可见</a-button>
        <a-button class="tool-item" @click="touchVisible(false)">全部不可见</a-button>
        <a-button class="tool-item" @click="touchDownload(true)">全部可下</a-button>
        <a-button class="tool-item" @click="touchDownload(false)">全部不可下</a-button>
      </div>
      <!-- 列表 -->
      <a-table
        class="table-view"
        :data-source="dataSource"
        :columns="columns"
        :row-key="record => record.id"
        :pagination="false"
        :loading="isLoading"
        @change="handleTableChange"
      >
        <!-- 自定义行内容 -->
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'visible'">
            <a-switch
              checked-children="开"
              un-checked-children="关"
              :disabled="isLoading"
              :checked="!!record.reseller_drama.is_visible"
              @change="changeSwitch(record, 'visible', $event)"
            />
          </template>
          <template v-if="column.key === 'download'">
            <a-switch
              checked-children="开"
              un-checked-children="关"
              :disabled="isLoading"
              :checked="!!record.reseller_drama.is_download"
              @change="changeSwitch(record, 'download', $event)"
            />
          </template>
        </template>
      </a-table>
    </div>
  </a-drawer>
</template>

<script setup>
import { message, Modal } from 'ant-design-vue'
import { ref, reactive, defineExpose } from 'vue'
import Pub from '@/utils/public'
import {
  resellerDramaList,
  resellerIsDownload,
  resellerIsVisible,
  resellerIsAllDownload,
  resellerIsAllVisible
} from '@/api/copyright'

// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 详情
const detail = ref({})
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '剧名',
    dataIndex: 'remark_name',
    key: 'remark_name'
  },
  {
    title: '是否可见',
    dataIndex: 'visible',
    key: 'visible'
  },
  {
    title: '是否可下载源片',
    dataIndex: 'download',
    key: 'download'
  }
])

// 打开抽屉
function showDrawer (record) {
  detail.value = record
  visible.value = true
  // 获取剧集列表
  getList()
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    visible.value = false
  }
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 获取推广数据列表
function getList () {
  isLoading.value = true
  const params = {
    reseller_id: detail.value.id,
    page: 1,
    page_size: 10000
  }
  resellerDramaList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

function touchVisible (boolean) {
  Modal.confirm({
    title: '提示',
    content: `${boolean ? '是否全部可见？' : '是否全部不可见？'}`,
    cancelText: '取 消',
    okText: '确 定',
    onOk: () => {
      const params = {
        reseller_id: detail.value.id,
        is_visible: boolean ? 1 : 0
      }
      resellerIsAllVisible(params).then(res => {
        isLoading.value = false
        const { code, msg } = res
        if (code === 0) {
          message.success('操作成功')
          getList()
        } else {
          message.error(res.message || msg)
        }
      })
    }
  })
}

function touchDownload (boolean) {
  Modal.confirm({
    title: '提示',
    content: `${boolean ? '是否全部可下？' : '是否全部不可下？'}`,
    cancelText: '取 消',
    okText: '确 定',
    onOk: () => {
      const params = {
        reseller_id: detail.value.id,
        is_download: boolean ? 1 : 0
      }
      resellerIsAllDownload(params).then(res => {
        isLoading.value = false
        const { code, msg } = res
        if (code === 0) {
          message.success('操作成功')
          getList()
        } else {
          message.error(res.message || msg)
        }
      })
    }
  })
}

// 是否开启
function changeSwitch (record, type, boolean) {
  if (type === 'visible') {
    // 可见
    const params = {
      reseller_id: detail.value.id,
      drama_id: record.id,
      is_visible: boolean ? 1 : 0
    }
    resellerIsVisible(params).then(res => {
      isLoading.value = false
      const { code, msg } = res
      if (code === 0) {
        message.success('操作成功')
        getList()
      } else {
        message.error(res.message || msg)
      }
    })
  } else {
    // 下载
    const params = {
      reseller_id: detail.value.id,
      drama_id: record.id,
      is_download: boolean ? 1 : 0
    }
    resellerIsDownload(params).then(res => {
      isLoading.value = false
      const { code, msg } = res
      if (code === 0) {
        message.success('操作成功')
        getList()
      } else {
        message.error(res.message || msg)
      }
    })
  }
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.tool-view {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  .tool-item {
    margin-left: 8px;
  }
}
.content-item {
  line-height: 40px;
  .title {
    font-size: 14px;
  }
  .desc {
    font-size: 14px;
    color: #5A5A5A;
  }
}
</style>