<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>分销管理</a-breadcrumb-item>
    <a-breadcrumb-item>分销商管理</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 分销商： -->
    <span class="tool-title">分销商：</span>
    <a-input v-model:value="searchModel" style="width: 160px;" placeholder="请输入" />
    <!-- 商务 -->
    <span class="tool-title">商务：</span>
    <a-select allowClear v-model:value="businessIDModel" style="width: 160px;" placeholder="请选择">
      <a-select-option v-for="item in businessList" :key="item.id" :value="item.id">{{ item.nickname }}</a-select-option>
    </a-select>
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="touchSearch">查询</a-button>
    <a-button style="margin-left: 16px;" @click="touchReset">重置</a-button>
    <!-- 弹簧 -->
    <div style="flex: 1;"></div>
    <!-- 操作 -->
    <a-button type="primary" @click="touchAdd"><plus-outlined />添加分销商</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 分成比例 -->
      <template v-if="column.key === 'wechat_cash_rate' || column.key === 'other_cash_rate'">
        {{ record[column.key] + '%' }}
      </template>
      <template v-if="column.key === 'cash_rate'">
        <a-tooltip>
          <template #title>
            <div style="white-space: nowrap;">IAP微小{{ record.wechat_cash_rate || 0 }}%，IAP其他{{ record.other_cash_rate || 0 }}%</div>
            <div style="white-space: nowrap;">IAA广告{{ record.iaa_ad_cash_rate || 0 }}%，IAA微小{{ record.iaa_wechat_cash_rate || 0 }}%，IAA其他{{ record.iaa_other_cash_rate || 0 }}%</div>
          </template>
          <a>查看</a>
        </a-tooltip>
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <div style="white-space: nowrap;">
          <a class="operation-item" @click="touchEdit(record)">编辑</a>
          <a class="operation-item" @click="touchFLEdit(record)">片库设置</a>
        </div>
      </template>
    </template>
  </a-table>
  <!-- 新增 -->
  <Add ref="RefAdd" @success="getList"></Add>
  <!-- 片库 -->
  <FilmLibrary ref="RefFilmLibrary"></FilmLibrary>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import { resellerList, resellerBusiness } from '@/api/copyright'
import Add from './components-reseller-manager/Add'
import FilmLibrary from './components-reseller-manager/FilmLibrary'
import { ResellerAttrs } from '@/utils/constantList'

// 每日数据
let RefAdd = ref(null)
let RefFilmLibrary = ref(null)
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 分销商
let searchModel = ref(undefined)
let search = ref(undefined)
// 商务
let businessIDModel = ref(undefined)
let businessID = ref(undefined)
let businessList = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '分销商ID',
    dataIndex: 'id',
    key: 'id',
    width: '8%'
  },
  {
    title: '分销商名称',
    dataIndex: 'name',
    key: 'name',
    width: '10%'
  },
  {
    title: '客户端',
    dataIndex: 'platform_name',
    key: 'platform_name',
    width: '30%'
  },
  // {
  //   title: '微小分成比例',
  //   dataIndex: 'wechat_cash_rate',
  //   key: 'wechat_cash_rate'
  // },
  // {
  //   title: '其他分成比例',
  //   dataIndex: 'other_cash_rate',
  //   key: 'other_cash_rate'
  // },
  {
    title: '分成比例',
    dataIndex: 'cash_rate',
    key: 'cash_rate',
    width: '8%'
  },
  {
    title: '入库日期',
    dataIndex: 'create_time',
    key: 'create_time',
    width: '10%',
    width: '10%'
  },
  {
    title: '商务',
    dataIndex: 'business_name',
    key: 'business_name',
    width: '8%'
  },
  {
    title: '合同截止日期',
    dataIndex: 'contract_deadline',
    key: 'contract_deadline',
    width: '10%'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 初始化选择项列表
  getSelectList()
  // 获取推广数据列表
  getList()
})

// 新增
function touchAdd () {
  RefAdd.value.showDrawer()
}

// 编辑
function touchEdit (record) {
  RefAdd.value.showDrawer(record)
}
// 片库设置
function touchFLEdit (record) {
  RefFilmLibrary.value.showDrawer(record)
}

// 查询
function touchSearch () {
  // 调整参数
  search.value = searchModel.value
  businessID.value = businessIDModel.value
  pagination.current = 1
  // 获取列表
  getList()
}

// 重置
function touchReset () {
  businessIDModel.value = undefined
  businessID.value = undefined
  searchModel.value = undefined
  search.value = undefined
  // 初始化获取
  touchSearch()
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 初始化选择项列表
function getSelectList () {
  resellerBusiness({ type: 1 }).then(res => {
    const { code, data } = res
    if (code === 0) {
      businessList.value = data
    }
  })
}

// 获取推广数据列表
function getList () {
  isLoading.value = true
  const params = {
    name: search.value,
    business_id: businessID.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  resellerList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data.data
      dataSource.value = list
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}
</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}
.operation-item {
  margin-right: 8px;
}
</style>